.countdown-clock{
    margin-right : -20px;
    display : inline-block;
    >div{
        display : inline-block;
        padding-right : 20px;
        text-align : center;
        .font-serif;
        .fz-4;

        >span{
            .font-heading;
            .square(80px);
            color : @light-base;
            font-size: 45px;
            display : block;
            line-height : 80px;
            background-color : @brand-primary;
        }
    }
}