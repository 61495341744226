/* Hot fix shortcode page layout */
/* Shortcode button list */
.shortcode-button-list{

}

.shortcode-parallax-1 {
    padding-top : 175px;
    padding-bottom : 180px;

    @media @xs-max {
        padding-top : 125px;
        padding-bottom : 130px;
    }
}

.shortcode-parallax-2 {
    padding-top : 130px;
    padding-bottom : 100px;
}