
.post {
    border-bottom  : 1px solid @gray-lighter;
    padding-bottom : 35px;

    &.post-index:not(:first-child) {
        padding-top : 70px;
    }

    // Post header
    .__header {
        margin-bottom : 30px;
    }

    // Post title
    .__title {
        .font-heading;
        font-size     : @font-size-h3-l;
        margin-bottom : 15px;
    }

    // Post quote
    .__quote {
        display : block;
        padding: 30px 35px 40px 70px;
        border: 6px solid fade(@light-base , 25%);
        border-radius : 6px;

        .__qcontent {
            position : relative;
            font-size : @font-size-h3-l;
            font-weight : 500;
            margin-bottom : 25px;

            &:before{
                position : absolute;
                display : block;
                left : -55px;
                padding: 15px 10px 0;
                content:'\201C';
                .font-serif;
                font-size : 80px;
                line-height : @line-height-computed ;
            }
        }

        .__qauthor{
            .font-heading;
            .fz-4;
        }

        @media @xs-max {
            padding: 60px 15px 30px;

            .__qcontent:before{
                top : -40px;
                left : -10px;
            }
        }

    }

    // Post meta
    .__meta {
        margin-bottom : 25px;
        .font-serif-italic;

        >span{
            display : inline-block;
        }

        > span:not(:last-child) {
            margin-right : 30px;
        }

        .__value {
            color : @brand-primary;
        }
    }
}


.blog-masonry-container{
    .clearfix();
    position : relative;
    margin-left : -10px;
    margin-right : -10px;

    .post-masonry{
        margin:0 10px;
    }

    .__quote{
        padding: 30px 25px 0px 50px;
        background-color :transparent;
        color:@gray-dark;
        border-bottom: 0;

        .__qcontent{
            position : relative;
            margin-bottom : 10px;
            font-weight : 600;
            .fz-5;
            
            &:before{
                top : 0;
                display : block;
                left : -40px;
                padding: 5px 10px 0;
                font-size : 45px;
                line-height : @line-height-computed ;
            }
        }
        
        .__qauthor{
            margin-bottom : 0;
            .fz-6;
            color:@brand-primary;
        }
    }

    .post{
        border-bottom: 0;
        margin-bottom : 40px;
        background-color : @gray-lighter + #0A0A0A;

        .__header{
            margin-bottom : 0;
        }

        .__main{
            padding: 20px 15px 0 15px;
        }

        .__title{
            .fz-4;
            margin-bottom : 10px;
        }

        .__meta{
            margin-bottom : 10px;
            > span:not(:last-child) {
                margin-right : 20px;
            }
        }

    }

}


.post-left-image{
    .clearfix;

    @media @sm {
        .__header{
            width : 48%;
            float : left;
            margin-right : 30px;
        }

        &.post-quote{
            .__header{
                width : 100%;
            }
        }

        .__title{
            margin-top : -0.2em;
            margin-bottom : 10px;
            line-height : 1.4;
        }

        .__meta{
            .fz-6-s;
            margin-bottom : 15px;

            > span:not(:last-child) {
                margin-right : 20px;
            }
        }

        .__main{
            overflow: hidden;
        }
    }

}