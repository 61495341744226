.group-homepage-item{
    text-align : center;
    padding-left : 15px;
    padding-right : 15px;
}

.block-homepage-item{
    margin-bottom : 30px;
    display : inline-block;
    max-width: 460px;
    width : 100%;
    padding-left : 15px;
    padding-right : 15px;
    .clearfix;
}

.block-homepage-item .__image{
    width : 100%;
    height: 0;
    padding-top: 88%;
    overflow: hidden;
    float : left;
    margin-bottom : 40px;
    border: 1px solid #EEEEEE;
    
    img{
        .easing-animation-long();
        position : absolute;
        width : 100%;
        left : 0;
        top : 0;
    }

    .__view-more{
        transform: translateY(60px);
    }

    &:hover {
        img{
            transform: translateY(-20px);
        }
        .__view-more{
            transform: translateY(0);
        }
    }
}

.choose-layout-style{
    margin-bottom : 40px;

    .__layout{
        position : relative;
        padding-left : 15px;
        padding-right : 15px;
        margin-bottom : 60px;
        float : left;
        
        img{
            .center-block;
            margin-bottom : 40px;
        }
    }

    @media @md {
        .__layout{
            width : 20%;
        }
    }
    @media @sm-max {
        .__layout{
            display : inline-block;
            float : none;
        }
    }
}