/* Slick slider core */
/* Slider */
.slick-slider {
    position                    : relative;
    display                     : block;
    -moz-box-sizing             : border-box;
    box-sizing                  : border-box;
    -webkit-user-select         : none;
    -moz-user-select            : none;
    -ms-user-select             : none;
    user-select                 : none;
    -webkit-touch-callout       : none;
    -khtml-user-select          : none;
    -ms-touch-action            : pan-y;
    touch-action                : pan-y;
    -webkit-tap-highlight-color : transparent;
}

.slick-list {
    position : relative;
    display  : block;
    overflow : hidden;
    margin   : 0;
    padding  : 0;
}

.slick-list:focus {
    outline : none;
}

.slick-list.dragging {
    cursor : pointer;
    cursor : hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform : translate3d(0, 0, 0);
    -moz-transform    : translate3d(0, 0, 0);
    -ms-transform     : translate3d(0, 0, 0);
    -o-transform      : translate3d(0, 0, 0);
    transform         : translate3d(0, 0, 0);
}

.slick-track {
    position : relative;
    top      : 0;
    left     : 0;
    display  : block;
}

.slick-track:before,
.slick-track:after {
    display : table;
    content : '';
}

.slick-track:after {
    clear : both;
}

.slick-loading .slick-track {
    visibility : hidden;
}

.slick-slide {
    display    : none;
    float      : left;
    height     : 100%;
    min-height : 1px;
}

[dir='rtl'] .slick-slide {
    float : right;
}

.slick-slide img {
    display : block;
}

.slick-slide.slick-loading img {
    display : none;
}

.slick-slide.dragging img {
    pointer-events : none;
}

.slick-initialized .slick-slide {
    display : block;
}

.slick-loading .slick-slide {
    visibility : hidden;
}

.slick-vertical .slick-slide {
    display : block;
    height  : auto;
    border  : 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display : none;
}

/* Slider Custom */

.slick-slider {
    position : relative;
    //overflow: hidden;
    &.slider-initialized {
        display : block;
    }
}

.slick-list {
    .slick-loading & {
        background : #FFFFFF url("../images/gif/ajax-loader.gif") center center no-repeat;
    }
}

//.slick-slide{
//    display : inline-block!important;
//}

/* Arrows */
.slick-arrow {
    .easing-animation();
    position         : absolute;
    top              : 50%;
    transform        : translateY(-50%);
    line-height      : 1;
    border: 0;
    border-radius: 50%;
    font-size : 0;
    background-color : transparent;
    z-index          : 515;
    overflow         : hidden;
    opacity          : 0;
    color:@light-base;

    &:before {
        display                : block;
        text-align             : center;
        font-family            : "megatron" !important;
        font-style             : normal !important;
        font-weight            : normal !important;
        font-variant           : normal !important;
        text-transform         : none !important;
        -webkit-font-smoothing : antialiased;
        font-size : 40px;
        text-shadow: 0 0 1px @gray-dark;
        
        @media @xs-max {
            font-size : 32px;
        }
    }
}

.arrow-gray{
    .slick-arrow{
        color: fade(@gray-base, 15%);

        &:before{
            text-shadow: 0 0 1px @light-base;
        }
    }
}

.slick-slider:hover {
    .slick-arrow {
        opacity : 1;
    }
}

.slick-prev {
    left : 0;
    &:before {
        content: "\e1ae";
    }
}

.slick-next {
    right     : 0;
    &:before {
        content: "\e1aa";
    }
}

@media (min-width : 1400px) {
    .slick-prev {
        left : 30px;
    }

    .slick-next {
        right : 30px;
    }

    .outer-direction{
        .slick-prev {
            left : -80px;
        }

        .slick-next {
            right : -80px;
        }
    }
}

.small-arrow{
    .slick-arrow
}

/* Dots */

.slick-dots {
    display    : block;
    list-style : none;
    bottom     : 0;
    position   : absolute;
    text-align : center;
    width      : 100%;
    li {
        display  : inline-block;
        position : relative;
        margin   : 0 4px;

        button {
            .easing-animation();
            display          : block;
            .square(11px);
            font-size        : 0;
            padding          : 0;
            background-color : transparent;
            border           : 1px solid @light-base;
            border-radius    : 50%;
        }

        &.slick-active button, button:hover {
            background-color : @light-base;
        }
    }
}

// Image Slider

.image-slider {
    .slider {
        .__item {
            .__image {
                img {
                    width: 100%;
                }
            }
        }
    }

    .slick-arrow {
        width: 40px;
        height: 40px;
        border-radius: 0;
        font-size : 0;
        background-color : fade(@gray-base,80%);
        z-index          : 515;
        overflow         : hidden;
        opacity          : 0;
        color:@light-base;

        &:before {
            font-size : 18px;
            position: relative;
            text-shadow: none;
        }
    }

    .slick-prev {
        left : 0;
        &:before {
            content: "\e11c";
            left: -1px;
        }
    }

    .slick-next {
        right     : 0;
        &:before {
            content: "\e169";
            right: -1px;
        }
    }

    .slick-dots {
        font-size: 0;
        bottom: 20px;
        li {

            button {
                .square(8px);
            }

            &.slick-active button, button:hover {
                background-color : @light-base;
            }
        }
    }
}

.image-slider-large {
    padding-bottom: 80px;

    .slider {
        .__item {
            .__image {
                img {
                    width: 100%;
                }
            }
        }
    }

    .slick-arrow {
        width: 48px;
        height: 70px;
        border-radius: 0;
        font-size : 0;
        background-color : fade(@gray-base,80%);
        z-index          : 515;
        overflow         : hidden;
        opacity          : 0;
        color:@light-base;

        &:before {
            font-size : 18px;
            position: relative;
            text-shadow: none;
        }
    }

    .slick-prev {
        left : 0;
        &:before {
            content: "\e11c";
            left: -1px;
        }
    }

    .slick-next {
        right     : 0;
        &:before {
            content: "\e169";
            right: -1px;
        }
    }

    .slick-dots {
        font-size: 0;
        bottom: -44px;
        li {

            button {
                .square(8px);
                border: 0;
                background-color : @gray-lighter;
            }

            &.slick-active button, button:hover {
                background-color : @gray-light;
            }
        }
    }
}
