.block-easy-piechart{
    text-align : center;
    
    @media @md {
        .__header{
            margin-bottom : 15px;
        }
        .__footer{
            margin-top : 60px;
        }
    }
}
.easy-piechart{
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;

    .progressbar-text{
        .font-heading;
        .square(95px);
        border-radius : 50%;
        font-size : @font-size-h2 - 2px;
        line-height : 95px;
        background-color : @brand-secondary;
        color: @light-base!important;
    }
    path:first-child{
        stroke: @brand-secondary;
    }
    path:last-child{
        stroke: @brand-info;
    }
}



.block-piechart{
    .center-block;
    max-width: 400px;
}

.piechart{
    width : 100%;
    display : flex;
    align-items: center;
    margin-bottom : 40px;
    .chart{
        width : 160px;
        height : 160px;
        float : left;
        canvas{
            width : 100%;
            height : 100%;
        }
    }
    .pie-legend{
        float : left;
        padding-left : 30px;
        li{
            margin-bottom : 15px;
            display : block;
        }
        span{
            display : inline-block;
            vertical-align: middle;
            margin-right : 15px;
            line-height : 1.2;
        }
        .__color{
            .square(30px);
        }
    }
}