.dropcap-1 {
    span.first-letter {
        border:1px solid;
        font-size: 38px;
        display: inline-block;
        width: 56px;
        height: 56px;
        float: left;
        font-weight: 700;
        line-height: 56px;
        margin-right: 15px;
        text-align: center;
    }

    &.style-1 {
        span.first-letter {
            background-color : @brand-primary;
            color: @light-base;
            border-color: @brand-primary;
        }
    }


    &.style-2 {
        span.first-letter {
            color: @light-base;
            border-color: @brand-secondary;
            background-color: @brand-secondary;
        }
    }

    &.style-3 {
        span.first-letter {
            border-color: @gray-dark;
            color: @gray-dark;
        }
    }
}

.dropcap-2 {
    span.first-letter {
        border:1px solid;
        font-size: 38px;
        display: inline-block;
        width: 56px;
        height: 56px;
        float: left;
        font-weight: 700;
        line-height: 56px;
        margin-right: 15px;
        text-align: center;
        border-radius: 50%;
    }

    &.style-1 {
        span.first-letter {
            background-color : @brand-primary;
            color: @light-base;
            border-color: @brand-primary;
        }
    }


    &.style-2 {
        span.first-letter {
            color: @light-base;
            border-color: @brand-secondary;
            background-color: @brand-secondary;
        }
    }

    &.style-3 {
        span.first-letter {
            border-color: @gray-dark;
            color: @gray-dark;
        }
    }
}

.dropcap-3 {
    span.first-letter {
        float: left;
        font-size: 55px;
        line-height: 55px;
        font-weight: 700;
        margin-right: 15px;
    }

    &.style-1 {
        span.first-letter {
            color: @brand-primary;
        }
    }


    &.style-2 {
        span.first-letter {
            color: @brand-secondary;
        }
    }

    &.style-3 {
        span.first-letter {
            color: @gray-dark;
        }
    }
}