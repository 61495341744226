form{
    input[type="text"],input[type="search"],input[type="email"],input[type="password"],input[type="tel"],input[type="number"],textarea{
        .easing-animation();
        padding: 9px 20px;
        width : 100%;
        border: 1px solid @gray-border;
        margin-bottom : 30px;
        .font-heading;
        &:focus{
            border-color : @brand-primary;
        }
    }

    input[type="search"]{
        box-sizing: border-box;
    }

    label{
        .font-heading;
    }

    textarea{
        resize:none;
        height : 200px;
    }
    
    .__row{
        position : relative;
        margin-bottom : 30px;

        input{
            display : inline-block;
            vertical-align: middle;
            margin-bottom : 0;
        }

        .__text{
            display : inline-block;
            vertical-align: middle;
        }
    }
}

.group-contact-left-header{
    .__header{
        padding-bottom : 40px;
    }
    @media @md {
        position : relative;
        .__header{
            position : absolute;
            height : 100%;
            top : 0;
            left : 0;
        }
    }

}

.login-popup{
    max-width : 325px;
    padding: 15px;
    
    input[type="text"] , input[type="password"]{
        margin-bottom : 20px;
    }
    
    .__row{
        margin-bottom : 25px;
        line-height : 1.2;
        .__text{
            margin-top : -2px;
        }
    }

    .__separator{
        position : relative;
        .font-heading;
        margin-top : @line-height-computed / 2;
        margin-bottom : @line-height-computed / 2;

        .__text{
            background-color : @light-base;
            display : inline-block;
            padding: 0 15px;
        }

        &:before{
            content: "";
            position : absolute;
            display : block;
            width : 100%;
            height : 0;
            top : 50%;
            left : 0;
            border-bottom : 1px solid @gray-border;
            z-index : -1;
        }
    }

    .__forgotten{
        text-decoration: underline;
    }
}

.register-popup{
    max-width: 500px;
    padding: 15px;
    input[type="text"] , input[type="password"], input[type="email"]{
        margin-bottom : 20px;
    }
    label{
        text-align : left;
        display : block;
    }
}
